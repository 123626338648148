export const data = {
    path: '/about',
    seo: {
        title: 'About',
        description:
            'The Pack is a digital agency dedicated to create web experiences that reflect your brand values and extend your real-world business.',
    },
    hero: {
        title: '<span>Together</span> we create',
        text: [
            'We treat your brand like an operating system, making it responsive and iterative, with immersive experiences and user journeys meticulously crafted to support your business objectives.',
        ],
    },
    problems_we_solve: {
        title: 'Problems <span>we solve</span>',
        powers: [
            {
                title: 'Uninspiring design',
                text: [
                    'Websites have to impress on first impression. Great design can amplify quality, legitimacy, and trust — vital for attracting users and keeping them coming back. We’ll help you inspire customers with demos, animations, lookbooks, and a narrative flow that keeps them clicking, tapping and swiping to a buying decision.',
                ],
            },
            {
                title: 'Unengaging User experience',
                text: [
                    'Great user experiences (UX) define online success. Users need to be engaged and entertained. They also want to find what they need quickly and move through your site with a fast and seamless flow. We make sure your web presence is immersive, fast, and easy to use — regardless of device or OS.',
                ],
            },
            {
                title: 'Underperforming websites',
                text: [
                    "Great design and UX won't mean much if your site is unresponsive and laggy, with glitchy apps or slow page load. Most web sessions these days happen on mobile. Does your site render beautifully on a smartphone screen? We’ll consider the audience, evaluate your site’s functionality, and choose the technology platforms that deliver maximum performance and usability.",
                ],
            },
            {
                title: 'Under-delivering on strategy',
                text: [
                    "Web design is an art, but it's also a fusion of marketing tactics: matching audience needs to interface, content, tech platforms and channels. We analyse you objectives, find the gaps in your current web offer, then create experiences that draw target users in — and keep them there.",
                ],
            },
        ],
    },
    our_approach: {
        slides: [
            {
                slide_title: 'Our approach',
                text: [
                    'At The Pack we place a lot of trust in good vibes. We believe great client relationships start with great chemistry. Do you value creativity, technical expertise, great service and nice people? We’ll get on like a house on fire.',
                    'You tell us the business problems that need solving. We’ll listen, think, offer advice, work with you on the best way forward, and get you to a working prototype quickly so you can test drive, feedback, and modify ‘til everything feels just right. Then we start developing the final product.',
                    'From idea to reality, our service is end-to-end. Trust us to look after everything for you.',
                ],
            },
            {
                slide_title: 'Phase 1',
                main_title: 'Explore<br/> & Define',
                tasks: [
                    'Planning & discovery workshop',
                    'Understanding user needs',
                    'Define personas',
                ],
                text: [
                    'Every project starts with a clear understanding of your business and the end users you need to reach. How do we align your objectives with their needs and interests — both today and in two years’ time? We’ll create a strategy that scales, so your website can <b>grow with you.</b>',
                ],
            },
            {
                slide_title: 'Phase 2',
                main_title: 'Experience<br/> design',
                tasks: [
                    'Information architecture',
                    'Purchase journeys and user flows',
                    'Wireframing',
                ],
                text: [
                    'Every touchpoint in the customer journey has to be aligned with your business objectives. Once goals and audiences have been defined, we scope the technical and interface requirements needed to deliver, then demonstrate how they’ll be used in the <b>finished product.</b>',
                ],
            },
            {
                slide_title: 'Phase 3',
                main_title: 'User<br/> interface',
                tasks: ['Design UI', 'Embed branding', 'Create a digital prototype'],
                text: [
                    'Next we build a working prototype. More than a proof-of-concept, it will give you clear view of the final product while ensuring enough flexibility for course corrections along the way. We believe in making ideas visual quickly, then modifying incrementally — ’til everything <b>feels just right.</b>',
                ],
            },
            {
                slide_title: 'Phase 4',
                main_title: 'Development<br/> & Launch',
                tasks: ['Development', 'Test and refine', 'Go live!', 'Measurement and analysis'],
                text: [
                    'Once the visual and operational details of the website are agreed, we get to work building it. That means trialling and refineing all functionality first to ensure everything works as promised. When testing is complete and you say you’re happy, we <b>move to launch.</b>',
                ],
            },
            {
                slide_title: 'Like our approach?',
                text: [
                    'Contact us today to arrange a call or ask us about rates, clients, expertise, portfolio — or anything else.',
                ],
                button: {
                    text: "Let's connect",
                    href: '/contact',
                },
            },
        ],
    },
    cta: {
        title: 'Success is the sum of many tactics',
        text: 'Questions about approach? Have an idea you’d like to talk through? Get in touch with us today.',
        href: '/contact',
    },
    featured_partners: {
        title: 'Your on-demand dev team',
        text: [
            'Want to scale your capabilities without growing headcount?  Along with work for clients we lend our skills and expertise to agencies too. Ask how we can become an extension of your team.',
        ],
        button: {
            text: 'Contact us',
            href: '/contact',
        },
    },
    images_slider: {
        images: [
            {
                path: 'about/images-slider/the-team-discussing.jpg',
                alt: 'The team discussing',
            },
            {
                path: 'about/images-slider/mike-julius-noraly-discussing.jpg',
                alt: 'Mike, Julius and Noraly discussing',
            },
            {
                path: 'about/images-slider/silvester-designing.jpg',
                alt: 'Silvester designing',
            },
            {
                path: 'about/images-slider/thomas-working.jpg',
                alt: 'Thomas working',
            },
            {
                path: 'about/images-slider/manouk-developing.jpg',
                alt: 'Manouk developing',
            },
            {
                path: 'about/images-slider/mike-developing.jpg',
                alt: 'Mike developing',
            },
            {
                path: 'about/images-slider/james-mike-discussing.jpg',
                alt: 'James and Mike discussing',
            },
            {
                path: 'about/images-slider/thomas-james-discussing.jpg',
                alt: 'Thomas and James discussing',
            },
        ],
    },
    employees: {
        title: 'Meet the team',
    },
    featured_carreers: {
        title: 'Careers',
        text: [
            'We are always looking for fresh talent. If you’re in for a lot of amazing projects, feel free to contact us about available jobs! We’d love to hear it.',
        ],
        button: {
            href: '/careers',
            text: 'Join our team',
        },
    },
};
