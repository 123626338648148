import React from 'react';
import { Helmet } from 'react-helmet';

//Data
import { siteMetadata } from '@root/gatsby-config';
import ThePackImage from '@images/openGraph/the-pack-homepage.jpg';

const SEO = props => {
    const {
        baseURL = `https://the-pack.nl`,
        lang = siteMetadata.lang,
        title = siteMetadata.title,
        description = siteMetadata.description,
        keywords = siteMetadata.keywords,
        author = siteMetadata.author,
        image = ThePackImage,
        image_alt = `The Pack - Digital Agency homepage`,
        localeAlternates = [],
        path,
    } = props;

    const htmlAttributes = {
        lang,
        xmlns: 'http://www.w3.org/1999/xhtml',
        prefix: 'og: https://ogp.me/ns#',
    };
    htmlAttributes['xml:lang'] = lang;

    return (
        <Helmet
            htmlAttributes={htmlAttributes}
            title={title}
            defaultTitle="Page"
            titleTemplate="%s | The Pack - Digital Agency"
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    name: `keywords`,
                    content: keywords,
                },
                {
                    property: `og:title`,
                    content: `${title} | The Pack - Digital Agency`,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: image,
                },
                {
                    property: `og:image:alt`,
                    content: image_alt,
                },
                {
                    property: `og:site_name`,
                    content: `The Pack - Digital Agency`,
                },
                {
                    property: 'og:locale',
                    content: lang,
                },
                {
                    name: `image`,
                    content: image,
                },
                {
                    name: `twitter:image`,
                    content: image,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: author,
                },
                {
                    name: `twitter:title`,
                    content: `${title} | The Pack - Digital Agency`,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },
            ]}
        >
            {localeAlternates.map(locale => {
                return (
                    <link
                        rel="alternate"
                        href={locale.url}
                        hrefLang={locale.lang}
                        key={locale.lang}
                    />
                );
            })}
            {path && <link rel="canonical" href={`${baseURL}${path}`} />}
        </Helmet>
    );
};

export default SEO;
