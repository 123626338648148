export const data = {
    path: '/contact',
    seo: {
        title: 'Contact',
        description:
            'Contact The Pack - Digital Agency via our form or check out our contact informations',
    },
    hero: {
        title: 'We are ready to <span>Geek out!</span>',
        sub_title: "Are you? <br/> Let's connect",
        office_title: 'Office',
        contact_title: 'Contact',
    },
};
