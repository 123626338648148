import { quotes } from '@data/en/quotes';

export const data = {
    path: '/work',
    seo: {
        title: 'Work',
        description:
            'Creating a succesfull web experience is more than meets the eye. Take a look at our work and discover how we solve problems with digital solutions.',
    },
    hero: {
        title: 'Real problems <br/>digital <span>solutions</span>',
        text: [
            "Creating a succesfull web experience is more than meets the eye. Take a deep dive into recent cases and discover how we solve problems with digital solutions. Here are just a few of the recent projects we've brought to life. With more to come!",
        ],
    },
    featured_clients: {
        title: 'We work with those who are brave enough to do things differently.',
    },
    quotes_slider: {
        title: 'What’s it like working with The Pack?',
        quotes,
    },
};
