import { CONTENT_COMPONENT_TYPES, EMPLOYMENT_TYPES, PAYMENT_FREQUENTY } from '@lib/constants';

export const freelancerBackEndDeveloper = {
    path: '/careers/freelancer-back-end-developer',
    seo: {
        title: 'Back-end developer',
        description:
            'Do you dream code, love to learn new technologies, live in the cloud and solve problems like a boss? We’re looking for an enthusiastic code hungry back-end developer to help us build secure and fast web apps.',
        schema: {
            baseSalery: 150,
            paymentFrequenty: PAYMENT_FREQUENTY.MONTH,
            jobBenefits: `A great working atmosphere. A great opportunity to develop yourself and grow to be a professional. Your own workstation. Awesome colleagues. Time to brainstorm with team members. Mario cart. SSX and Smash tourneys. A chance to beat Mike at chess (please help us). Free trips to Blast galaxy.`,
            datePosted: '2021-11-08',
            description:
                'You’ll be responsible for designing and developing backend applications. Create rich feature APIs and interact with other APIs. Implement Continuous Integration and Continuous Delivery process. Deploy and run cloud native solutions. Implement code quality assurance process and code review practices. Implement product release process.',
            employmentType: EMPLOYMENT_TYPES.CONTRACTOR,
            experienceRequirements:
                'Proficient in backend development with JavaScript, TypeScript and NodeJS. Hands-on experience with microservices (design, development and deployment) and application scalability and performance. Experience designing and implementing REST APIs/GraphQL. Experience with continuous integration (CI/CD toolset). Experience with cloud platforms (preferred GoogleCloud).',
            occupationalCategory: '15-1254.00	Web Developers',
            responsibilities:
                'You’ll be responsible for designing and developing backend applications. Create rich feature APIs and interact with other APIs. Implement Continuous Integration and Continuous Delivery process. Deploy and run cloud native solutions. Implement code quality assurance process and code review practices. Implement product release process.',
            skills: 'Javascript/Typescript, NodeJS, security, REST apis/GraphQL, CI/CD toolsets, experience with cloud platforms (preferred GoogleCloud), pré Shopify',
        },
    },
    title: 'Back-end developer',
    work_hours: 'Freelancer',
    hero: {
        title: 'Get ready to code!',
        text: [
            'Do you dream code, love to learn new technologies, live in the cloud and solve problems like a boss? We’re looking for an enthusiastic code hungry back-end developer to help us build secure and fast web apps.',
        ],
    },
    preface: {
        title: 'This is <br/>The Pack!',
        components: [
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'The Pack is a digital agency specialising in unique and immersive online experiences. We’re dedicated to creating web experiences that reflect your brand values and extend your real-world business presence into the virtual realm.',
            },
        ],
    },
    images_slider: {
        images: [
            {
                path: 'careers/intern-front-end-developer/images-slider/mike-developing.jpg',
                alt: 'Mike developing',
            },
            {
                path: 'careers/intern-front-end-developer/images-slider/thomas-drawing.jpg',
                alt: 'Thomas drawing',
            },
            {
                path: 'careers/intern-front-end-developer/images-slider/blast-galaxy-awwwards-honors-and-marvel-gauntlet.jpg',
                alt: 'Blast Galaxy website Awwwards honors and a marvel gauntlet',
            },
            {
                path: 'careers/intern-front-end-developer/images-slider/thomas-james-discussing.jpg',
                alt: 'Thomas and James discussing',
            },
            {
                path: 'careers/intern-front-end-developer/images-slider/james-thomas-playing-chess.jpg',
                alt: 'James and Thomas playing chess',
            },
            {
                path: 'careers/intern-front-end-developer/images-slider/playing-mario-super-smash-on-fridays.jpg',
                alt: 'Playing super mario super smash on fridays',
            },
        ],
    },
    content_one: {
        title: 'The Job',
        components: [
            {
                type: CONTENT_COMPONENT_TYPES.TITLE,
                text: 'What you will be doing',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'At The Pack we have a diverse range of client’s for whom we create web based solutions. You’ll be responsible for designing and developing backend applications. Create rich feature APIs and interact with other APIs. Implement Continuous Integration and Continuous Delivery process. Deploy and run cloud native solutions. Implement code quality assurance process and code review practices. Implement product release process.',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'You have a hands-on approach and feel comfortable with "learning by doing". You are not afraid to discuss best practices and approaches with other developers and advice our clients about the best technologies / stack for their needs.',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'If you dream code, are a nerd and love to work in a young team, we are happy to have you on board! Join us in building fast, secure, efficient and future friendly web apps. We embrace a culture of experimentation and constantly strive for improvement and learning.',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TITLE,
                text: 'What we are looking for',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'A flexible, dedicated freelance Back-end developer that has experience in setting up a full back-end application in NodeJS, implement CI/CD process and deploy and run cloud native solutions. Feels free to advice us in other technologies.',
            },
            {
                type: CONTENT_COMPONENT_TYPES.BUTTON,
                text: 'Read enough? Apply now',
                href: '#form',
                button_props: {
                    tertiary: true,
                },
            },
            {
                type: CONTENT_COMPONENT_TYPES.LIST,
                title: 'Requirements',
                items: [
                    'You are a flexible, dedicated, freelance Back-end Developer',
                    'Have a good understanding of Javascript/Typescript, NodeJS, REST APIs/GraphQL, Databases and their best practices',
                    'Experience with Nest.js and TypeORM',
                    'Feel free to advice us in other techonologies',
                    'Able to work independently and in a team',
                    'You are not afraid to ask questions or discuss best practices and approaches',
                    'Good at problem solving',
                    'Has an HBO mindset',
                    'Nice to have: experience with WordPress / PHP / Laravel',
                ],
            },
            {
                type: CONTENT_COMPONENT_TYPES.LIST,
                title: 'Whats in it for you?',
                items: [
                    'A great work environment',
                    'A great opportunity to develop yourself and grow to be a professional',
                    'Your personal workstation',
                    'Working in a supportive and fun team of young professionals',
                    'Awesome colleagues',
                    'Regular brainstorm session with no restraints',
                    'Mario cart, SSX and Smash tourneys',
                    'Possibilities to work on your thesis',
                    'A chance to beat Mike at chess (please help us)',
                    'Free trips to Blast Galaxy',
                ],
            },
        ],
    },
    cards: {
        title: 'About work ethic',
        cards: [
            {
                title: 'Flexible',
                text: [
                    "Sometimes it's better to work outside of the 9-5. We do what’s necessary to get the job done but keep the personal and professional in balance. We take the time we need for ourselves.",
                ],
            },
            {
                title: 'Team-ish',
                text: [
                    'We work together and support one another. Collective goals come first, but there’s room for individual ambition and personal growth too.',
                ],
            },
            {
                title: 'Ambitious',
                text: [
                    'We want to try new things, disrupt, be creative, and get there first with ideas, innovations, and suggestions — for clients and ourselves.',
                ],
            },
            {
                title: 'Game-obsessed',
                text: [
                    [
                        "We're gamers to the core. Nerdy and competitive. Obsessed with reaching the next level.",
                    ],
                ],
            },
            {
                title: 'Commited to good vibes',
                text: [
                    "Life's too short to obsess over work 24/7. Take time to switch off and smell the coffee. We do.",
                ],
            },
            {
                title: 'Too small for politics',
                text: [
                    'Our guiding principle: just do the right thing; the rest will sort itself.',
                ],
            },
        ],
    },
    games_slider: {
        title: 'Game on',
        text: [
            'Check out our massive games library. Hope to see you on the racetrack or battlefield soon! ',
        ],
    },
};
