import { data as work } from '@data/en/pages/work';
import { data as about } from '@data/en/pages/about';
import { data as contact } from '@data/en/pages/contact';
import { data as careers } from '@data/en/pages/careers';
import { data as cookies } from '@data/en/pages/cookies';
import termsAndConditions from '@static/files/Algemene-Voorwaarden.pdf';
import disclaimer from '@static/files/Disclaimer.pdf';
import privacy from '@static/files/Privacy-statement.pdf';

export const footerNavigation = [
    {
        name: 'about',
        path: about.path,
    },
    {
        name: 'work',
        path: work.path,
    },
    // {
    //     name: 'careers',
    //     path: careers.path,
    //     type: 'careers',
    // },
    {
        name: 'contact',
        path: contact.path,
    },
    {
        name: 'terms & conditions',
        path: termsAndConditions,
    },
    {
        name: 'privacy',
        path: privacy,
    },
    {
        name: 'disclaimer',
        path: disclaimer,
    },
    {
        name: 'cookies',
        path: cookies.path,
    },
    {
        name: 'subbrand',
        path: 'https://deonliners.nl',
    },
];
