const path = require('path');

module.exports = {
    // flags: { PRESERVE_WEBPACK_CACHE: true },
    siteMetadata: {
        title: `The Pack - Digital Agency`,
        description: `Together we create - The Pack - Digital Agency`,
        author: `Mike Haklander`,
        siteUrl: 'https://the-pack.nl/',
        lang: 'en',
        keywords:
            'The Pack, website, user experience design, user interface design, marketing, strategy, developer, wormer, web development',
    },
    plugins: [
        'gatsby-plugin-sass',
        {
            resolve: 'gatsby-plugin-google-tagmanager',
            options: {
                id: 'GTM-T7KJJWZ',
            },
        },
        {
            resolve: 'gatsby-plugin-robots-txt',
            options: {
                host: 'https://the-pack.nl/',
                sitemap: 'https://the-pack.nl/sitemap.xml',
                policy: [{ userAgent: '*', allow: '/', disallow: '*.pdf' }],
            },
        },
        'gatsby-plugin-react-svg',
        'gatsby-plugin-image',
        'gatsby-plugin-react-helmet',
        'gatsby-plugin-sitemap',
        'gatsby-plugin-offline',
        'gatsby-plugin-sharp',
        'gatsby-transformer-sharp',
        {
            resolve: 'gatsby-plugin-manifest',
            options: {
                name: 'The Pack - Digital Agency',
                short_name: 'The Pack',
                start_url: '/',
                background_color: '#df1a3f',
                theme_color: '#df1a3f',
                display: 'minimal-ui',
                icon: 'src/images/the-pack-favicon.png',
            },
        },
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                path: `${__dirname}/src/images`,
                name: 'images',
            },
        },
        {
            resolve: 'gatsby-plugin-web-font-loader',
            options: {
                typekit: {
                    id: 'bst3pfp',
                },
            },
        },
        {
            resolve: `gatsby-plugin-alias-imports`,
            options: {
                alias: {
                    '@components': path.resolve(__dirname, 'src/components'),
                    '@data': path.resolve(__dirname, 'src/data'),
                    '@images': path.resolve(__dirname, 'src/images'),
                    '@lib': path.resolve(__dirname, 'src/lib'),
                    '@styles': path.resolve(__dirname, 'src/styles'),
                    '@hooks': path.resolve(__dirname, 'src/hooks'),
                    '@fonts': path.resolve(__dirname, 'src/fonts'),
                    '@static': path.resolve(__dirname, 'static'),
                    '@root': path.resolve(__dirname),
                },
            },
        },
        {
            resolve: 'gatsby-plugin-exclude',
            options: { paths: ['/blog', '/blog/*', '/careers', '/careers/*'] },
        },
    ],
};
