export const options = {
    street: 'Pakhuisplein 42J',
    zip_code: '1531MZ',
    city: 'Wormer',
    country: 'The Netherlands',
    country_code: 'NL',
    email: 'info@the-pack.nl',
    phone_number: '0850470150',
    google_maps_link: 'https://goo.gl/maps/pMQsnrUntaVWYiq5A',
    socials: {
        instagram: 'thepackdigitalagency',
        linkedin: 'https://nl.linkedin.com/company/the-pack-studio',
    },
};
