import React, { useState } from 'react';
import useDocumentScrollThrottled from '@hooks/useDocumentScrollThrottled';
import { useLockBodyScroll } from 'react-use';

//Data
import { mainNavigation } from '@data/en/navigations/main-navigation';
import { careersList } from '@data/en/careers/index';

//Styles
import styles from './Header.module.scss';

//Components
import { Link } from 'gatsby';

//Images
import ThePackShortLogoBlack from '@images/the-pack-short-logo-black.svg';
import ThePackShortLogoWhite from '@images/the-pack-short-logo-white.svg';

export const Header = props => {
    const { white } = props;
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    useLockBodyScroll(mobileNavOpen);

    const [shouldHideHeader, setShouldHideHeader] = useState(false);
    const [transparentHeader, setTransparentHeader] = useState(true);

    const MINIMUM_SCROLL = 0;
    const TIMEOUT_DELAY = 400;

    useDocumentScrollThrottled(callbackData => {
        const { previousScrollTop, currentScrollTop } = callbackData;
        const isScrolledDown = previousScrollTop < currentScrollTop;
        const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

        setTimeout(() => {
            setTransparentHeader(currentScrollTop === 0);
            setShouldHideHeader(isScrolledDown && isMinimumScrolled);
        }, TIMEOUT_DELAY);
    });

    const closeNav = () => {
        setMobileNavOpen(false);
    };

    const openNav = () => {
        setMobileNavOpen(true);
    };

    const toggleNav = () => {
        if (mobileNavOpen) closeNav();
        else openNav();
    };

    return (
        <header
            className={`${styles.header} ${white && transparentHeader ? styles.headerWhite : ''} ${
                shouldHideHeader ? styles.headerNavUp : ''
            } ${transparentHeader ? styles.headerTransparent : ''}`}
        >
            <div className={styles.header__wrapper}>
                <div className={styles.header__brand}>
                    <Link to="/">
                        {(white && transparentHeader) || mobileNavOpen ? (
                            <ThePackShortLogoWhite onClick={closeNav} />
                        ) : (
                            <ThePackShortLogoBlack onClick={closeNav} />
                        )}
                    </Link>
                </div>

                <nav
                    className={`${styles.header__nav} ${
                        mobileNavOpen ? styles.header__navActive : ''
                    }`}
                >
                    <ul className={styles.header__navList}>
                        {mainNavigation.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    className={`${styles.header__navItem} ${
                                        item.type === 'careers' && careersList.length
                                            ? styles.header__navItemCareers
                                            : ''
                                    }`}
                                >
                                    <Link
                                        activeClassName={styles.header__navLinkActive}
                                        to={item.path}
                                        className={styles.header__navLink}
                                        onClick={closeNav}
                                        partiallyActive={true}
                                    >
                                        {item.name}
                                    </Link>

                                    {item.type === 'careers' && careersList.length ? (
                                        <span className={styles.header__careersCounter}>
                                            {careersList.length}
                                        </span>
                                    ) : null}
                                </li>
                            );
                        })}
                    </ul>
                </nav>

                <button
                    onClick={toggleNav}
                    className={`${styles.header__navTrigger} ${
                        mobileNavOpen ? styles.header__navTriggerActive : ''
                    }`}
                >
                    <div></div>
                    <div></div>
                    <div></div>
                </button>
            </div>
        </header>
    );
};
