import { data as work } from '@data/en/pages/work';
import { data as about } from '@data/en/pages/about';
import { data as contact } from '@data/en/pages/contact';
import { data as careers } from '@data/en/pages/careers';

export const mainNavigation = [
    {
        name: 'about',
        path: about.path,
    },
    {
        name: 'work',
        path: work.path,
    },
    // {
    //     name: 'careers',
    //     path: careers.path,
    //     type: 'careers',
    // },
    {
        name: 'contact',
        path: contact.path,
    },
];
