import React from 'react';

//Styles
import styles from './Container.module.scss';

export const Container = props => {
    const { className = null, children, small = false, style = {} } = props;

    return (
        <div
            style={style}
            className={`${styles.container} ${small ? styles.containerSmall : ''} ${
                className ? className : ''
            }`}
        >
            {children}
        </div>
    );
};
