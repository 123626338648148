export const MAILAPI_URL = `https://mailpoint.the-pack.nl/wp-admin/admin-ajax.php`;

export const CONTENT_COMPONENT_TYPES = {
    TITLE: 'title',
    SUB_TITLE: 'sub-title',
    TEXT: 'text',
    LIST: 'list',
    BUTTON: 'button',
    IMAGE: 'image',
    REF: 'ref',
};

export const INPUT_TYPES = {
    TEXT: 'text',
    EMAIL: 'email',
    TEL: 'tel',
    TEXTAREA: 'textarea',
    GEEK_QUESTION: 'geek_question',
    FILE: 'file',
    HIDDEN: 'hidden',
};

export const EMPLOYMENT_TYPES = {
    FULL_TIME: 'FULL_TIME',
    PART_TIME: 'PART_TIME',
    CONTRACTOR: 'CONTRACTOR',
    TEMPORARY: 'TEMPORARY',
    INTERN: 'INTERN',
    VOLUNTEER: 'VOLUNTEER',
    OTHER: 'OTHER',
};

export const PAYMENT_FREQUENTY = {
    HOUR: 'HOUR',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    YEAR: 'YEAR',
};

export const DEVTOOLS = {
    WORDPRESS: {
        name: 'Wordpress',
    },
    CANVAS: {
        name: 'Canvas',
    },
    THREEJS: {
        name: 'Three.js',
    },
    REACT: {
        name: 'React',
    },
    NEXTJS: {
        name: 'Next.js',
    },
    LARAVEL: {
        name: 'Laravel',
    },
    VUE: {
        name: 'Vue',
    },
    GSAP: {
        name: 'GSAP',
    },
    FRAMER_MOTION: {
        name: 'Framer Motion',
    },
};
