import { sources } from '@data/en/quotes/sources';

export const blastGalaxy = {
    quote: [
        'The Pack is a group of experts with great passion for their profession and an inexhaustible commitment to our digital needs. They use a targeted, no-nonsense and structured approach and that has resulted in an award-winning website. Because of The Pack we can focus on our core tasks with the support of them.',
    ],
    from: 'Chris',
    role: 'Founder',
    company: 'Blast Galaxy',
    source: {
        name: sources.GOOGLE_REVIEWS,
        url: 'https://www.google.com/maps/contrib/113587624966667092373/place/ChIJ_UqHh9UGxkcRr8GBupm6QLs/@52.4920573,4.7966001,17z/data=!4m6!1m5!8m4!1e1!2s113587624966667092373!3m1!1e1?hl=nl-NL',
    },
};
