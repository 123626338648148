import { CONTENT_COMPONENT_TYPES } from '@lib/constants';

export const data = {
    path: '/careers',
    seo: {
        title: 'Careers',
        description:
            'A great team means strong individuals united around big ideas. We work hard, game hard, and give the best high fives in town.',
    },
    hero: {
        title: 'Run with <span>The Pack</span>',
        text: [
            'A great team means strong individuals united around big ideas. We work hard, game hard, and give the best high fives in town.',
        ],
        button: {
            href: '#careersList',
            text: 'See our current opportunities',
            tertiary: true,
        },
    },
    content_one: {
        title: 'Devote yourself',
        components: [
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'Digital is constantly evolving. We’re committed to keeping up and expanding our knowledge and expertise as new modes, trends, and technologies take hold.',
            },
        ],
    },
    content_two: {
        title: 'Love the work',
        components: [
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'Pushing everyone to do better is how we roll. We fight for the great — lasering in on original solutions that help clients achieve their objectives.',
            },
        ],
    },
    featured_clients: {
        title: 'We work with those who are brave enough to do things differently.',
    },
    images_slider: {
        images: [
            {
                path: 'careers/images-slider/point-blank-2.jpg',
                alt: 'Point Blank 2 - Arcade shooting game',
            },
            {
                path: 'careers/images-slider/blast-galaxy-awwwards-honors-and-marvel-gauntlet.jpg',
                alt: 'Blast Galaxy website Awwwards honors and a marvel gauntlet',
            },
            {
                path: 'careers/images-slider/mike-julius-noraly-discussing.jpg',
                alt: 'Mike, Julius and Noraly discussing',
            },
            {
                path: 'careers/images-slider/playing-chess.jpg',
                alt: 'Playing chess',
            },
            {
                path: 'careers/images-slider/playing-mario-super-smash-on-fridays.jpg',
                alt: 'Playing super mario super smash on fridays',
            },
            {
                path: 'careers/images-slider/nintendo-64.jpg',
                alt: 'Nintendo 64',
            },
            {
                path: 'careers/images-slider/pixel-the-dog.jpg',
                alt: 'Pixel the dog',
            },
        ],
    },
    we_are_slider: {
        prefix: 'We are',
        words: ['Flexible', 'Team-ish', 'Ambitious', 'Gamers', 'Good vibes', 'Creative'],
    },
    careers: {
        title: 'Career positions',
    },
    games_slider: {
        title: 'Game on',
        text: [
            'Check out our massive games library. Hope to see you on the racetrack or battlefield soon! ',
        ],
    },
};
