import { wordwrap } from './wordwrap';

export const formatPhoneNumber = (number, is_attr = false) => {
    if (!number) {
        return false;
    }

    number = String(number);

    //Removes every character except numbers
    number = number.replace('/[^0-9]/', '').replace(/^0/, '');

    return is_attr ? `+31${number}` : `+31 (0) ${wordwrap(number, 3, ' ', true)}`;
};
