import { CONTENT_COMPONENT_TYPES, EMPLOYMENT_TYPES, PAYMENT_FREQUENTY } from '@lib/constants';

export const internFrontEndDeveloper = {
    path: '/careers/intern-front-end-developer',
    seo: {
        title: 'Front-end developer',
        description:
            'Do you dream code, love to learn new technologies, solve problems like a boss and are you looking for a fourth year internship? We’re looking for an enthusiastic code hungry front-end developer to help us build custom WordPress themes with rich animations and functionalities.',
        schema: {
            baseSalery: 150,
            paymentFrequenty: PAYMENT_FREQUENTY.MONTH,
            jobBenefits: `A great working atmosphere. A great opportunity to develop yourself and grow to be a professional. Your own workstation. Awesome colleagues. Time to brainstorm with team members. Mario cart. SSX and Smash tourneys. Possibilities to work on your thesis. A chance to beat Mike at chess (please help us). Free trips to Blast galaxy.`,
            datePosted: '2021-09-16',
            description:
                'You’ll be responsible for developing and maintaining WordPress custom themes.',
            educationRequirements:
                'HBO - Are in your fourth year of CMD (or any other programming schooling)',
            employmentType: EMPLOYMENT_TYPES.INTERN,
            experienceRequirements:
                'You are able to solve digital problems with creative ideas and translate visual designs, pixel perfect, to code. You can setup WordPress to the clients needs and are able to prevent possible issues, that dynamic text or images, could bring to the website by taking all possibilities into account.',
            occupationalCategory: '15-1254.00	Web Developers',
            responsibilities:
                'You’ll be responsible for developing and maintaining WordPress custom themes.',
            skills: 'You are able to solve digital problems with creative ideas and translate visual designs, pixel perfect, to code. You can setup WordPress to the clients needs and are able to prevent possible issues, that dynamic text or images, could bring to the website by taking all possibilities into account.',
        },
    },
    title: 'Front-end developer',
    work_hours: 'Intern - Fulltime',
    hero: {
        title: 'Get ready to code!',
        text: [
            'Do you dream code, love to learn new technologies, solve problems like a boss and are you looking for a fourth year internship? We’re looking for an enthusiastic code hungry front-end developer to help us build custom WordPress themes with rich animations and functionalities.',
        ],
    },
    preface: {
        title: 'This is <br/>The Pack!',
        components: [
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'The Pack is a digital agency specialising in unique and immersive online experiences. We’re dedicated to creating web experiences that reflect your brand values and extend your real-world business presence into the virtual realm.',
            },
        ],
    },
    images_slider: {
        images: [
            {
                path: 'careers/intern-front-end-developer/images-slider/mike-developing.jpg',
                alt: 'Mike developing',
            },
            {
                path: 'careers/intern-front-end-developer/images-slider/thomas-drawing.jpg',
                alt: 'Thomas drawing',
            },
            {
                path: 'careers/intern-front-end-developer/images-slider/blast-galaxy-awwwards-honors-and-marvel-gauntlet.jpg',
                alt: 'Blast Galaxy website Awwwards honors and a marvel gauntlet',
            },
            {
                path: 'careers/intern-front-end-developer/images-slider/thomas-james-discussing.jpg',
                alt: 'Thomas and James discussing',
            },
            {
                path: 'careers/intern-front-end-developer/images-slider/james-thomas-playing-chess.jpg',
                alt: 'James and Thomas playing chess',
            },
            {
                path: 'careers/intern-front-end-developer/images-slider/playing-mario-super-smash-on-fridays.jpg',
                alt: 'Playing super mario super smash on fridays',
            },
        ],
    },
    content_one: {
        title: 'The Job',
        components: [
            {
                type: CONTENT_COMPONENT_TYPES.TITLE,
                text: 'What you will be doing',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'At The Pack we have a diverse range of client’s for whom we create web based solutions. You’ll be responsible for developing and maintaining custom WordPress themes. You have a hands-on approach and feel comfortable with "learning by doing". You are not afraid to discuss best practices and approaches with other developers and advice our clients about the best technologies / stack for their needs. You’ll be making high-end custom Wordpress themes that were designed by our in house designers.',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'If you dream code, are a nerd and love to work in a young team, we are happy to have you on board! Join us in building new interfaces that will be fast, responsive, efficient and future friendly. We embrace a culture of experimentation and constantly strive for improvement and learning.',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TITLE,
                text: 'What we are looking for',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'You are able to solve digital problems with creative ideas and translate visual designs, with pixel perfect accuracy, to code. You can setup Wordpress to the clients needs and are able to prevent possible issues, that dynamic text or images, could bring to the website by taking all possibilities into account.',
            },
            {
                type: CONTENT_COMPONENT_TYPES.BUTTON,
                text: 'Read enough? Apply now',
                href: '#form',
                button_props: {
                    tertiary: true,
                },
            },
            {
                type: CONTENT_COMPONENT_TYPES.LIST,
                title: 'Requirements',
                items: [
                    'Are in your fourth year of Communication & Multimedia Design (or an other  schooling program specialized in front end development) ',
                    'Have a good understanding of HTML, CSS en Javascript and their best practices',
                    'Able to work independently and in a team',
                    'You are not afraid to ask questions or discuss best practices and approaches',
                    'Good at problem solving',
                    'Able to translate visual designs with pixel perfect accuracy to code',
                    'Has an HBO mindset',
                    'Nice to have: experience with WordPress / PHP',
                ],
            },
            {
                type: CONTENT_COMPONENT_TYPES.LIST,
                title: 'Whats in it for you?',
                items: [
                    'A great work environment',
                    'A great opportunity to develop yourself and grow to be a professional',
                    'Your personal workstation',
                    'Working in a supportive and fun team of young professionals',
                    'Awesome colleagues',
                    'Regular brainstorm session with no restraints',
                    'Mario cart, SSX and Smash tourneys',
                    'Possibilities to work on your thesis',
                    'A chance to beat Mike at chess (please help us)',
                    'Free trips to Blast Galaxy',
                ],
            },
        ],
    },
    cards: {
        title: 'About work ethic',
        cards: [
            {
                title: 'Flexible',
                text: [
                    "Sometimes it's better to work outside of the 9-5. We do what’s necessary to get the job done but keep the personal and professional in balance. We take the time we need for ourselves.",
                ],
            },
            {
                title: 'Team-ish',
                text: [
                    'We work together and support one another. Collective goals come first, but there’s room for individual ambition and personal growth too.',
                ],
            },
            {
                title: 'Ambitious',
                text: [
                    'We want to try new things, disrupt, be creative, and get there first with ideas, innovations, and suggestions — for clients and ourselves.',
                ],
            },
            {
                title: 'Game-obsessed',
                text: [
                    [
                        "We're gamers to the core. Nerdy and competitive. Obsessed with reaching the next level.",
                    ],
                ],
            },
            {
                title: 'Commited to good vibes',
                text: [
                    "Life's too short to obsess over work 24/7. Take time to switch off and smell the coffee. We do.",
                ],
            },
            {
                title: 'Too small for politics',
                text: [
                    'Our guiding principle: just do the right thing; the rest will sort itself.',
                ],
            },
        ],
    },
    games_slider: {
        title: 'Game on',
        text: [
            'Check out our massive games library. Hope to see you on the racetrack or battlefield soon! ',
        ],
    },
};
