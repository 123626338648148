import React, { useEffect, useRef, useState } from 'react';
import { motion, useReducedMotion } from 'framer-motion';

//Data
import { footerNavigation } from '@data/en/navigations/footer-navigation';
import { options } from '@data/en/options/options';

//Styles
import styles from './Footer.module.scss';

//Lib
import { formatPhoneNumber } from '@lib/formatPhoneNumber';

//Components
import { Container } from '@components/Utilities/Container/Container';
import { Link } from 'gatsby';

//Images
import Logo from '@images/the-pack-logo-white.svg';
import ArrowUpRed from '@images/icons/arrow-up-red.svg';
import ArrowRightUpWhite from '@images/icons/arrow-right-up-white.svg';
import LinkedInIconWhite from '@images/icons/linkedin-white.svg';
import InstagramIconWhite from '@images/icons/instagram-white.svg';

export const Footer = () => {
    const {
        socials: { instagram, linkedin },
    } = options;
    const ref = useRef();
    const shouldReduceMotion = useReducedMotion();
    const [parallax, setParallax] = useState(false);

    useEffect(() => {
        const makeSpaceFooter = () => {
            const footerHeight = ref.current.getBoundingClientRect().height - 3;
            const windowHeight = typeof window !== 'undefined' ? window.innerHeight : null;
            const previousSibling = ref.current.previousSibling;

            if (windowHeight && windowHeight > footerHeight && previousSibling) {
                previousSibling.style.marginBottom = `${footerHeight}px`;
                setParallax(true);
            } else if (previousSibling) {
                previousSibling.style.marginBottom = `0px`;
                setParallax(false);
            }
        };

        makeSpaceFooter();
        window.addEventListener('resize', makeSpaceFooter);

        return () => {
            window.removeEventListener('resize', makeSpaceFooter);
        };
    }, []);

    return (
        <footer ref={ref} className={`${styles.footer} ${parallax ? styles.footerParallax : ''}`}>
            <Container>
                <div className={styles.footer__top}>
                    <div className={styles.footer__connect}>
                        <Link to={'/contact'}>
                            Let's <br /> Connect
                            <ArrowRightUpWhite />
                        </Link>
                    </div>

                    <div className={styles.footer__contact}>
                        <div>
                            <h6 className={'h6'}>Office</h6>

                            <address className={styles.footer__address}>
                                <a
                                    className={styles.footer__link}
                                    href={options.google_maps_link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {options.street} <br />
                                    {options.zip_code} {options.city} <br />
                                    {options.country} <br />
                                </a>
                            </address>
                        </div>

                        <div>
                            <h6 className={'h6'}>Contact</h6>

                            <nav className={styles.contact__links}>
                                <ul>
                                    <li>
                                        <a
                                            className={styles.footer__link}
                                            href={`mailto:${options.email}`}
                                        >
                                            {options.email}
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            className={styles.footer__link}
                                            href={`tel:${formatPhoneNumber(
                                                options.phone_number,
                                                true,
                                            )}`}
                                        >
                                            {formatPhoneNumber(options.phone_number)}
                                        </a>
                                    </li>
                                </ul>
                            </nav>

                            {instagram || linkedin ? (
                                <nav className={styles.contact__socials}>
                                    <ul>
                                        {instagram && (
                                            <li>
                                                <a
                                                    href={`https://instagram.com/${instagram}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <InstagramIconWhite />
                                                </a>
                                            </li>
                                        )}

                                        {linkedin && (
                                            <li>
                                                <a href={linkedin} target="_blank" rel="noreferrer">
                                                    <LinkedInIconWhite />
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </nav>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className={styles.footer__bottom}>
                    <div className={styles.footer__copyright}>
                        <Link to="/">
                            <Logo />
                        </Link>

                        <p>&copy; {new Date().getFullYear()}</p>
                    </div>

                    <nav className={styles.footer__links}>
                        <ul className={styles.footer__linksList}>
                            {footerNavigation.map((item, index) => {
                                const { path, name } = item;
                                const isPDF = path.includes('.pdf');

                                return (
                                    <li key={index} className={styles.footer__linksItem}>
                                        {isPDF || name === 'cookies' || name === 'subbrand' ? (
                                            // eslint-disable-next-line react/jsx-no-target-blank
                                            <a
                                                href={path}
                                                target="_blank"
                                                className={`${styles.footer__link}`}
                                            >
                                                {name}
                                            </a>
                                        ) : (
                                            <Link to={path} className={`${styles.footer__link}`}>
                                                {name}
                                            </Link>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>

                    <div className={styles.footer__toTopButtonContainer}>
                        <motion.button
                            whileHover={
                                !shouldReduceMotion
                                    ? { y: [0, 10], transition: { yoyo: Infinity } }
                                    : {}
                            }
                            onClick={() => {
                                document.body.scrollTop = 0;
                                document.documentElement.scrollTop = 0;
                            }}
                        >
                            <ArrowUpRed />
                        </motion.button>
                    </div>
                </div>
            </Container>
        </footer>
    );
};
